


<template>
    <div class="UserHome">
      <div class="bottomDiv">
        <div class="index_title" style="border-bottom: none">余额退费</div>
        <div class="formDiv" style="width:80vw; margin-left: 8vw;">
          <el-form style="width:50vw; margin-left: 5vw;">
              
              <el-input
                style="width:50vw"
                v-model="FormData.phone"
                placeholder="请输入手机号"
              ></el-input>
            </el-form>
            <el-form label-position="left" style="width: 76vw; display: flex; justify-content: space-between;">
              <el-form-item style="width:50vw; margin-left: 5vw; margin-top: 2px;">
                <el-select v-model="FormData.type_id" placeholder="请选择充电类型" style="width:50vw">
                  <el-option v-for="car in carList" :key="car.value" :value="car.value" :label="car.label"/>
                </el-select>
              </el-form-item>
              <div class="BtnSearch" style="width: 18vw; height: 30px;" @click="searchBtn()">查询</div>
            </el-form>
          
           
             
                
         
         
          
        </div>
        <div class="tableDiv">
        <el-table
          ref="multipleTableRef"
          :data="tableData"
          border 
          v-loading="loading"
          height= "480px"
          style="
            width: 90%; 
            margin: 20px auto;
            overflow-y: auto;
          "
        >
            <!-- <el-table-column label="用户编号" prop="user_number" /> -->
            <el-table-column label="用户手机号" prop="phone" />
            <el-table-column label="充值车型" prop="type_id">
              <template #default="scope">
                <span v-if="scope.row.type_id == 2">电动车</span>
                <span v-if="scope.row.type_id == 4">电动汽车</span>
              </template>
            </el-table-column>
            <el-table-column label="充值金额(元)" prop="charge_amount" :formatter="rounding" />
            <el-table-column label="充值后账户余额(元)" prop="after_charge_amount" :formatter="rounding" />
            <el-table-column label="充值时间" prop="creat_time" />
            <el-table-column
            label="操作"
            width="110"
            align="center"
            fixed="right"
          >
            <template #default="scope">
              <!-- <el-button round color='#42a7a9' plain size="mini" @click="charge(scope.$index, scope.row)">充值</el-button> -->
              <el-button round type="warning" plain size="mini" @click="refund(scope.$index, scope.row)">退费</el-button>
            </template>
          </el-table-column>
          </el-table>
          
        </div>
        <div class="flex1">
          <p>充值总次数：<span>{{ tableData1.count_top_up }}</span></p>
          <p>充值总金额：<span>{{ tableData1.sum_top_up }}</span></p>
        </div>
          </div>
        
      <!-- </div> -->
      <!-- 退费弹窗 -->
      <div v-dialogdrag>
          <el-dialog
            v-model="Dialog1.editLevy"
            title="充值"
            width="900px"
            :before-close="closeDialog"
            class="dialog"
          >
            <div class="formDiv">
              <el-form label-position="right" label-width="100px">
               
                
                    <el-form-item label="用户手机号:">
                      <el-input
                        v-model="Dialog1.DialogData.phone"
                      ></el-input>
                    </el-form-item>
                
                 
                      <el-form-item label="退费金额(元):">
                        <el-select style="width: 100%;" v-model="Dialog1.DialogData.money" placeholder="请选择退款金额" @change="handlemoney">
                          <el-option v-for="moneys in moneyList" :key="moneys.value" :value="moneys.value" :label="moneys.label"/>
                          <!-- </el-option> -->
                        </el-select>
                    </el-form-item>
             
                      <el-form-item label="退费金额(元):">
                    <el-input
                      type="number"
                      v-model="Dialog1.DialogData.refund_amount"
                      placeholder="请输入退费金额(元)"
                    ></el-input>
                  </el-form-item>
              </el-form>
            </div>
            <template #footer>
              <span class="dialog-footer">
                <el-button class="Btn" @click="closeDialog">取消</el-button>
                <el-button class="Btn" type="primary" @click="submitDialog1">提交</el-button>
              </span>
            </template>
          </el-dialog>
        </div>
    </div>
  </template>
  <script>
import { onMounted, reactive, toRefs } from "vue-demi";
import { user_charge_detail,operator_data,re_money } from "@/request/api";
import { ElMessage } from "element-plus";

import ProvinceCityCountry from '../../assets/json/address'
export default {
  name: "InvestDetail",
  setup() {
    const data = reactive({
      //表单数据
      FormData: {
        orderTime:['',''],
      },
      isshow:false,
      isadmin:false,
      users:[],
      tableData: [],
      tableData1: {},
      carList:[
        {
          value:2,
          label:'电动车'
        },
        {
          value:4,
          label:'电动汽车'
        }
      ],
      moneyList:[
          {
            value:0,
            label:'全额退费'
          },
          {
            value:1,
            label:'手动输入'
          }
        ],
      values:'',
      //   分页
      currentPage:1,
      pageSize:20,
      total:400,
      checked:0,
      small:true,
      moneyrefund:'',
      loading: false,
      provinceData: ProvinceCityCountry.address,
      Dialog1: {
        editLevy: false,
        DialogData: {},
      },
    });
    const rounding=(row,column)=> {
      return parseFloat(row[column.property]?row[column.property]:'0').toFixed(2)
    }
    const closeDialog = () => {
      data.Dialog1.editLevy = false;
      data.Dialog1.editLevy = false;
    };
    const submitDialog1 = () => {
      console.log("提交");
      submitData1();
    };
    const formatDate =(row, column) => {
      // 获取单元格数据
      let data = row[column.property]
      if(data == null) {
          return null
      }
      let dt = new Date(data)
      return dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate() + ' ' + dt.getHours() + ':' + dt.getMinutes() + ':' + dt.getSeconds()
    }
    const searchBtn = () => {
      getList();
    };
    const handlemoney = (value) => {
      data.values = value
      if(data.values == 0){
        data.isshow = false
        // data.refunds.refund = data.refunds.total

      }else if(data.values == 1){
        data.isshow = true
      }
    };
    // const getTime = () => {
    //     let date = new Date;
    //     let y = date.getFullYear().toString();
    //     let m = (date.getMonth() + 1).toString().padStart(2, 0);
    //     var curretMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    //     var curretMonthDayCount = curretMonth.getDate();
    //     data.FormData.orderTime[0] = [y, m, 1].join("-")
    //     data.FormData.orderTime[1] = [y, m, curretMonthDayCount].join("-")
        
    //   }
    const getList = () => {
      const stDate = data.FormData.orderTime[0] ? data.FormData.orderTime[0] : undefined;
      const endDate = data.FormData.orderTime[1] ? data.FormData.orderTime[1] : undefined;
      const dataa = {
        username:data.FormData.username,
        operator_id : sessionStorage.getItem('userID'),
        phone:data.FormData.phone,
        type_id:data.FormData.type_id,
        start_execute_date: stDate,
        stop_execute_date: endDate
        
      }
      console.log(dataa,'dataa');
        data.loading = true;
        user_charge_detail(dataa).then((res) => {
          if (res) {
            console.log(res,'充值详情列表');
            data.loading = false;
            if (res.code == 200) {
              data.tableData = res.data;
              data.total = res.data.length;
              data.tableData1 = res
            } else {
              ElMessage.error(res.msg);
            }
          } else {
            ElMessage.error("数据获取失败");
          }
        });
    };
   
    const getUsername = () => {
      const username1 = localStorage.getItem('username')
      const users1 = []
      operator_data().then((res) => {
        console.log(res,'sssssss');
        data.users = res
        res.forEach((a)=>{    
          users1.push(a.username)
      })
      if(users1.indexOf(username1)==-1){
        data.isadmin = true
      }else{
        data.isadmin = false
        data.FormData.username = username1
      }
      });
    };
    const refund = (index, row) => {
      data.Dialog1.editLevy = true;
      data.Dialog1.DialogData = row;
    }
    const submitData1 = () => {
    if(data.values == 0){
      data.moneyrefund = data.Dialog1.DialogData.charge_amount
       
      }else if(data.values == 1){
        data.moneyrefund  = data.Dialog1.DialogData.refund_amount
      }
    const dataa = {
      refund:data.moneyrefund.toString(),
      out_trade_no:data.Dialog1.DialogData.out_trade_no.toString(),
      transaction_id:data.Dialog1.DialogData.transaction_id.toString(),
      total:data.Dialog1.DialogData.charge_amount.toString(),
      refund_reason:data.Dialog1.DialogData.refund_reason.toString(),
    }
    console.log(dataa,'dataadadad')
    re_money(dataa).then((res) => {
      console.log(res);
      if (res) {
        closeDialog()
        if (res.code == 200) {
          ElMessage({
            showClose: true,
            message: "退费成功",
            type: "success",
          });
          getList()
        } else {
          ElMessage.error(res.msg);
        }
      } else {
        ElMessage.error("提交失败");
      }

    });
  };
    onMounted(() => {
      getUsername();
      // getTime()
      getList();
      
        
    });
    return {
      ...toRefs(data),
      formatDate,
      searchBtn,
      // getTime,
      getList,
      closeDialog,
      getUsername,
      submitDialog1,
      refund,
      handlemoney,
      rounding
    };
  },
};
  </script>
  <style scoped>
  .bottomDiv{
    height: 100%;
    background: #fff;
  }
  .my-label {
    background: var(--el-color-success-light-9);
  }
  .my-content {
    background: var(--el-color-danger-light-9);
    width: 100%;
  }
  .pagination{
      display: flex;
      justify-content: space-between;
      padding: 0 67px;
      margin-top: -10px;
  }
  .flex1{
    display: flex;
    /* justify-content: start; */
    padding: 5px 10px;
    /* margin-top: -10px; */
  
}
.flex1 p{
  font-size: 14px;
  margin-right: 15px;
}
.flex1 span{
  color: red;
  
}
.index_title {
  font-size: 18px;
  color: #333;
  border-left: 5px solid #04a0c3;
  padding-left: 25px;
  margin-bottom: 15px;
}
  </style>